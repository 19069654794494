import { arcaService } from '@core/services/arcaService';
import { arenaService } from '@core/services/arenaService';
import { homeService } from '@core/services/homeService';
import { Application, ApplicationIds } from '@core/services/models/application';
import { useStores } from '@hooks/useStores/useStores';
import {
  loadApplicationDataActionCreator,
  loadUserAppsActionCreator,
  removeApplicationDataActionCreator,
} from '@state/dashboard/actions/dasboardActionCreator';
import { DashboardDispatchContext } from '@state/dashboard/context/DasboardContext';
import { type DashboardApplications } from '@state/dashboard/reducer/DashboardReducer';
import { type Application as ApplicationModel } from '@state/permissionsManagement/models/applicacion';
import { usePermissionsManagement } from '@state/permissionsManagement/usePermissionsManagement';
import moment from 'moment';
import { useCallback, useContext } from 'react';

const DATE_FORMAT = 'HH:mm DD/MM/YYYY';

export const useDashboard = () => {
  const dispatch = useContext(DashboardDispatchContext);
  const { getApplications } = usePermissionsManagement();
  const { tasksStore } = useStores();

  const getUserApplications = useCallback(async () => {
    const storedData = await getStoredUserApplications();
    let userApplications: ApplicationModel[];
    if (!storedData || getDiffMinutesDate(storedData.lastUpdate) >= 15) {
      userApplications = await getApplications();
      try {
        const ws = await arcaService.getWorkstreams();
        if (ws.length === 0) {
          userApplications = userApplications.filter((app) => app.name !== Application.arca().name);
        }
      } catch {
        userApplications = userApplications.filter((app) => app.name !== Application.arca().name);
      }
      saveUserApplications(userApplications);
    } else {
      userApplications = storedData.apps;
    }

    dispatch(loadUserAppsActionCreator(userApplications));
  }, [dispatch, getApplications]);

  const getDatacampusData = useCallback(async () => {
    const storedData = getAppFromStorage(ApplicationIds.DataCampus);
    const needsRefresh = !storedData || getDiffDaysDate(storedData.lastUpdate) >= 1;
    const dataCampusInfo = needsRefresh ? await homeService.getInfo() : storedData.data;

    if (needsRefresh) {
      saveAppOnStorage(ApplicationIds.DataCampus, {
        data: dataCampusInfo,
        lastUpdate: new Date().toString(),
      });
      getDatacampusData();
      return;
    }

    dispatch(
      loadApplicationDataActionCreator({
        [ApplicationIds.DataCampus]: {
          data: storedData.data,
          lastUpdate: moment(storedData.lastUpdate).format(DATE_FORMAT),
        },
      }),
    );
  }, [dispatch, tasksStore, homeService]);

  const getArcaData = useCallback(async () => {
    const storedData = getAppFromStorage(ApplicationIds.Arca);
    const needsRefresh = !storedData || getDiffDaysDate(storedData.lastUpdate) >= 1;

    const arcaData = needsRefresh ? await homeService.workstreamkpikpm() : storedData.data;

    if (needsRefresh) {
      saveAppOnStorage(ApplicationIds.Arca, { data: arcaData, lastUpdate: new Date().toString() });
      getArcaData();
      return;
    }

    dispatch(
      loadApplicationDataActionCreator({
        [ApplicationIds.Arca]: {
          data: storedData.data,
          lastUpdate: moment(storedData.lastUpdate).format(DATE_FORMAT),
        },
      }),
    );
  }, []);

  const getJoureyData = useCallback(async () => {
    const storedData = getAppFromStorage(ApplicationIds.Journey);

    const status =
      !storedData?.data?.status || getDiffDaysDate(storedData.lastUpdate) >= 1
        ? await homeService.generalStatusToday()
        : storedData.data.status;

    const alertsCount =
      !storedData?.data?.alertsCount || getDiffDaysDate(storedData.lastUpdate) >= 1
        ? await homeService.getAllAlerts()
        : storedData.data.alertsCount;
    const frequentRoute =
      !storedData?.data?.frequentRoute || getDiffDaysDate(storedData.lastUpdate) >= 1
        ? await homeService.mostFrequentRoute()
        : storedData.data.frequentRoute;

    const journeyData = { status, alertsCount, frequentRoute };

    if (!storedData || getDiffDaysDate(storedData.lastUpdate) >= 1) {
      saveAppOnStorage(ApplicationIds.Journey, {
        data: journeyData,
        lastUpdate: new Date().toString(),
      });
      getJoureyData();
      return;
    }
    dispatch(
      loadApplicationDataActionCreator({
        [ApplicationIds.Journey]: {
          data: storedData.data,
          lastUpdate: moment(storedData.lastUpdate).format(DATE_FORMAT),
        },
      }),
    );
  }, []);

  const getArenaData = useCallback(async () => {
    const storedData = getAppFromStorage(ApplicationIds.Arena);
    const needsRefresh = !storedData || getDiffDaysDate(storedData.lastUpdate) >= 1;

    const arenaData = needsRefresh ? await arenaService.getArenaInfo() : storedData.data;

    if (needsRefresh) {
      saveAppOnStorage(ApplicationIds.Arena, { data: arenaData, lastUpdate: new Date().toString() });
      getArenaData();
      return;
    }

    dispatch(
      loadApplicationDataActionCreator({
        [ApplicationIds.Arena]: {
          data: storedData.data,
          lastUpdate: moment(storedData.lastUpdate).format(DATE_FORMAT),
        },
      }),
    );
  }, [dispatch, arenaService]);

  const getStoredUserApplications = useCallback(async () => {
    const storage = window.localStorage.getItem(`user-apps-data`);
    if (storage) {
      return JSON.parse(storage);
    }
  }, []);

  const saveUserApplications = useCallback(async (apps: ApplicationModel[]) => {
    window.localStorage.setItem(`user-apps-data`, JSON.stringify({ apps, lastUpdate: new Date().toString() }));
  }, []);

  const getAppFromStorage: <T extends ApplicationIds>(id: T) => DashboardApplications[T] = useCallback(
    (id: ApplicationIds) => {
      const storage = window.localStorage.getItem(`app-${id}-data`);
      if (storage) {
        return JSON.parse(storage);
      }
    },
    [],
  );

  const storageKey: <T extends ApplicationIds>(id: T) => string = (id) => `app-${id}-data`;

  const saveAppOnStorage: <T extends ApplicationIds>(id: T, data: DashboardApplications[T]) => void = useCallback(
    (id, data) => {
      window.localStorage.setItem(storageKey(id), JSON.stringify(data));
    },
    [],
  );

  const removeAppOnStorage: <T extends ApplicationIds>(id: T) => void = useCallback((id) => {
    window.localStorage.removeItem(storageKey(id));
  }, []);

  const getDiffDaysDate = (date: Date | string): number => {
    const difference = new Date().getTime() - new Date(date).getTime();
    return difference / (1000 * 3600 * 24);
  };

  const getDiffMinutesDate = (date: Date | string): number => {
    const difference = new Date().getTime() - new Date(date).getTime();
    return difference / (1000 * 60);
  };

  const reloadAppData: <T extends ApplicationIds>(appId: T) => void = useCallback((appId) => {
    dispatch(removeApplicationDataActionCreator(appId));
    removeAppOnStorage(appId);

    switch (appId) {
      case ApplicationIds.DataCampus:
        getDatacampusData();
        break;

      case ApplicationIds.Arca:
        getArcaData();
        break;

      case ApplicationIds.Arena:
        getArenaData();
        break;

      case ApplicationIds.Journey:
        getJoureyData();
        break;
    }
  }, []);

  return { getUserApplications, getDatacampusData, getArcaData, getJoureyData, getArenaData, reloadAppData };
};
